import { styled } from '@mui/material/styles';
import { Card } from '@mui/material';

// Define styled components
export const StyledCard = styled(Card)(({ theme }) => ({
  margin: theme.spacing(4, 0, 2, 0),
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
}));
