import { DELETE, CREATE, UPDATE, FETCH, FETCH_ALL, REVIEW, CLEAR_REVIEWS } from "../constants/actionTypes";

export default (posts = [], action) => {
  switch (action.type) {
    case FETCH:
      return [action.payload];
    case FETCH_ALL:
      return action.payload;
    case CREATE:
      return [...posts, action.payload];
    case DELETE:
      return posts.filter((post) => post._id !== action.payload);
    case UPDATE:
    case REVIEW:
      return posts.map((post) => post._id === action.payload._id ? action.payload : post);
    case CLEAR_REVIEWS:
      return posts.map((post) => post._id === action.payload ? { ...post, reviews: [] } : post);
    default:
      return posts;
  }
}