import { styled } from '@mui/material/styles';
import { Avatar, Paper } from "@mui/material";

// Define styled components
export const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
}));

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  justifyContent: 'flex-center',
  margin: theme.spacing(1),
  backgroundColor: theme.palette.secondary.main,
}));


export const StyledForm = styled('form')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(3),
}));

export const StyledSubmit = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2),
}));