import { styled } from '@mui/material/styles';
import { Typography} from '@mui/material';
import { Link } from "react-router-dom";
import { Grid } from '@mui/material';

// StyledContainer will act as the main grid container
export const StyledContainer = styled(Grid)(({ theme }) => ({
    display: 'grid',
    width: '100%',
    gridTemplateColumns: 'repeat(2, 1fr)', // Create two equal columns for the thumbnails
    margin: theme.spacing(4, 2, 0, 0), // Add margin to the top of the grid
}));
  
  // StyledGrid will be used for the grid items, ensuring they take full width of their cell
  export const StyledGrid = styled(Grid)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center', // Center content within each grid item
  }));