import React, { useState } from "react";
import { Button, Grid, Typography, Container } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { GoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

import InputField from "../InputField/InputField";
import { signup, signin } from "../../actions/auth";
import { StyledPaper, StyledAvatar, StyledForm, StyledSubmit } from "./styles";

import { AUTH } from "../../constants/actionTypes";

const initialState = { firstName: "", lastName: "", email: "", password: "", confirmPassword: "" };

const Auth = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isSignup, setIsSignup] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isSignup) {
      dispatch(signup(formData, navigate));
      switchMode();
    }
    else {
      dispatch(signin(formData, navigate));
    }
  }

  const handleChange = (e) => { 
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }
  
  const handleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  }

  const switchMode = () => {
    setIsSignup((prevIsSignup) => !prevIsSignup);
    setFormData({...initialState, email: formData.email});
    setShowPassword(false);
  }

  const forgottenPassword = () => {
    navigate("/recovery");
  }

  const googleSuccess = async (res) => {
    const credential = res?.credential;
    
    try {
      dispatch({ type: AUTH, data: { result: jwtDecode(credential), token: credential } });
      navigate('/')
    }
    catch (error) {
      console.log(error);
    }
  }

  const googleError = (error) => {
    console.log(error);
    console.log("Google Sign In was unsuccessful. Try again later");
  }

  return (
    <Container component="main" maxWidth="xs">
      <StyledPaper elevation={3}>
        <StyledAvatar><LockOutlinedIcon /></StyledAvatar>
        <Typography variant="h5">{ isSignup ? "Sign Up" : "Sign In"}</Typography>
        <StyledForm onSubmit={handleSubmit}>
          <div>
            <Grid container spacing={2}>
              { isSignup && (
                <>
                  <InputField name="firstName" value={formData.firstName} label="First Name" autoFocus half handleChange={handleChange} />
                  <InputField name="lastName" value={formData.lastName} label="Last Name" half handleChange={handleChange} />
                </>
              )}
              <InputField name="email" value={formData.email}  label="Email Address" type="email" handleChange={handleChange} />
              <InputField name="password" value={formData.password} label="Password" type={showPassword ? "text" : "password"} handleShowPassword={handleShowPassword} handleChange={handleChange} />
              { isSignup && <InputField name="confirmPassword" label="Repeat Password" type="password" handleChange={handleChange} />}
            </Grid>
          </div>
          <StyledSubmit><Button type="submit" fullWidth variant="contained" color="primary">{ isSignup ? "Sign Up" : "Sign In"}</Button></StyledSubmit>
          
          <StyledSubmit>
                <GoogleLogin
                  onSuccess={googleSuccess}
                  onFailure={googleError}
                  cookiePolicy="single_host_origin"
                  uxMode="popup"
                  longTitle={false}
                  promptMomentNotification={false}
                  useFed={false}
                  theme="filled_blue"
                  size="large"
                  text={
                    'By logging in you accept the <a href="https://naesview.com/terms-and-conditions" target="_blank" rel="noopener noreferrer">terms and conditions</a> and <a href="https://naesview.com/privacy-policy" target="_blank" rel="noopener noreferrer">privacy policy</a>.'
                  }
                />
          </StyledSubmit>
          
          
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button onClick={switchMode}>{ isSignup ? "Already have an account? Sign In" : "Don't have an account? Sign Up"}</Button>
              <Button onClick={forgottenPassword}>{ "Forgotten password "}</Button>
            </Grid>
          </Grid>
        </StyledForm>
      </StyledPaper>
    </Container>
  );
}

export default Auth;