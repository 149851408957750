import * as api from '../api';
import { DELETE, REVIEW, CREATE, FETCH, FETCH_ALL, UPDATE, CLEAR_REVIEWS } from '../constants/actionTypes';

// Action Creators
export const getPosts = () => async (dispatch) => {
  try {
    const { data } = await api.fetchPosts();
    dispatch({ type: FETCH_ALL, payload: data });
  } catch (error) {
    console.log(error);
  }
}

// Action Creators
export const getPost = (id) => async (dispatch) => {
  try {
    const { data } = await api.fetchPost(id);
    dispatch({ type: FETCH, payload: data });
  } catch (error) {
    console.log(error);
  }
}

export const createPost = (post) => async (dispatch) => {
  try {
    const { data } = await api.createPost(post);
    dispatch({ type: CREATE, payload: data });
  } catch (error) {
    console.log(error);
  }
}

export const updatePost = (id, post) => async (dispatch) => {
  try {
    const { data } = await api.updatePost(id, post);
    dispatch({ type: UPDATE, payload: data });
  } catch (error) {
    console.log(error);
  }
}

export const deletePost = (id) => async (dispatch) => {
  try {
    await api.deletePost(id);
    dispatch({ type: DELETE, payload: id });
  } catch (error) {
    console.log(error);
  }
}

export const reviewPost = (id, review) => async (dispatch) => {
  try {
    const { data } = await api.reviewPost(id, review);
    dispatch({ type: REVIEW, payload: data });
  } catch (error) {
    console.log(error);
  }
}

export const clearReviews = ( id ) => async (dispatch) => {
  try {
    await api.clearReviews(id);
    dispatch({ type: CLEAR_REVIEWS, payload: id });
    
  } catch (error) {
    console.log(error);
  }
}