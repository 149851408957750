import React, { useState } from "react";
import { Button, Grid, Typography, Container } from "@mui/material";
import LockResetIcon from '@mui/icons-material/LockReset';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import InputField from "../../InputField/InputField";
import { StyledPaper, StyledAvatar, StyledForm, StyledSubmit } from "./styles";

import { AUTH } from "../../../constants/actionTypes";
import { recovery } from "../../../actions/auth";

const initialState = { firstName: "", lastName: "", email: "", password: "", confirmPassword: "" };

const RecoveryPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isSignup, setIsSignup] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    toast("If a user with this email exists, a password reset email will be sent to them", { type: "info" });
    
    dispatch(recovery(formData, navigate));
  }

  const handleChange = (e) => { 
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }
  
  const handleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  }

  const switchMode = () => {
    setIsSignup((prevIsSignup) => !prevIsSignup);
    setFormData({...initialState, email: formData.email});
    setShowPassword(false);
  }

  const forgottenPassword = () => {
    navigate("/recovery");
  }

  const googleSuccess = async (res) => {
    const credential = res?.credential;
    
    try {
      dispatch({ type: AUTH, data: { result: jwtDecode(credential), token: credential } });
      navigate('/')
    }
    catch (error) {
      console.log(error);
    }
  }

  const googleError = (error) => {
    console.log(error);
    console.log("Google Sign In was unsuccessful. Try again later");
  }

  return (
    <Container component="main" maxWidth="xs">
      <ToastContainer />
      <StyledPaper elevation={3}>
        <StyledAvatar><LockResetIcon /></StyledAvatar>
        <Typography variant="h5"> Reset password </Typography>
        <StyledForm onSubmit={handleSubmit}>
          <div>
            <Grid container spacing={2}>
              <InputField name="email" value={formData.email}  label="Email Address" type="email" handleChange={handleChange} />
            </Grid>
          </div>
          <StyledSubmit><Button type="submit" fullWidth variant="contained" color="primary">Reset password</Button></StyledSubmit>
        </StyledForm>
      </StyledPaper>
    </Container>
  );
}

export default RecoveryPage;