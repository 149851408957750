import { styled } from '@mui/material/styles';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link } from "react-router-dom";

export const StyledContainer = styled('div')({
  display: 'flex',                 // Use flexbox layout
  justifyContent: 'center',        // Center horizontally
  alignItems: 'center',            // Center vertically
  height: '100vh',                 // Full viewport height
  width: '100vw',                  // Full viewport width
  backgroundColor: '#f0f0f0'       // Optional: background color for visibility
});


// Define styled components
export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 15,
  width: '96%',
  // padding: theme.spacing(1, 8, 0, 8),
  margin: theme.spacing(1, "2%", 0, "2%"),
}));

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '90%',
  margin: theme.spacing(1),
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  color: 'inherit',
  textDecoration: 'none',
  '&:hover': {
    color: 'inherit',
  },
  '&:active': {
    color: 'inherit',
  },
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
  margin: theme.spacing(1),
  height: '100%',
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  color: 'inherit',
  textDecoration: 'none',
  '&:hover': {
    color: 'inherit',
  },
  '&:active': {
    color: 'inherit',
  },
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
  margin: theme.spacing(1),
  height: '100%',
}));

export const StyledHome = styled(Link)(({ theme }) => ({
  color: 'inherit',
  textDecoration: 'none',
  '&:hover': {
    color: 'inherit',
  },
  '&:active': {
    color: 'inherit',
  },
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'block',
  align: 'center',
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  color: 'inherit',
  textDecoration: 'none',
  '&:hover': {
    color: 'inherit',
  },
  '&:active': {
    color: 'inherit',
  }
}));