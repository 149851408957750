import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Checkbox, FormControlLabel  } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import FileBase from 'react-file-base64';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import Manager from './Manager/Manager';
import { createPost, updatePost } from '../../actions/posts';
import { StyledRemoveButton, StyledPaper, StyledTagsContainer, StyledSubmitButton, StyledTagFieldContainer, StyledTagField, StyledAddOptionButton, StyledViewportType, StyledDropdown, StyledFileInput } from './styles';

const Form = ({ currentId, setCurrentId }) => {
    const [postData, setPostData] = useState({ title: '', description: '', reviews: [], reviewers: [], buttons: [], labels: [], thumbnail: '', useComments: false, viewportType: 'simple'});
    const [isExpanded, setIsExpanded] = useState(false);
    const post = useSelector((state) => currentId ? state.posts.find((p) => p._id === currentId) : null);
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const user = JSON.parse(localStorage.getItem('profile'));
    
    const toggleExpand = () => {
        setIsExpanded((prevExpanded) => !prevExpanded);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (currentId) {
            dispatch(updatePost(currentId, { ...postData, name: user?.result?.name }));
        } else {
            dispatch(createPost({ ...postData, name: user?.result?.name }));
        }
        clear();
        navigate("/")

    }
    
    const clear = () => {
        setCurrentId(null);
        setPostData({ title: '', description: '', reviews: [], reviewers: [], buttons: [], labels: [], thumbnail: '', useComments: false, viewportType: 'simple'});
    }

    const handleButtonChange = (index, field, event) => {
        const newTags = [...postData.buttons];
        newTags[index] = { ...newTags[index], [field]: event.target.value };
        setPostData({ ...postData, buttons: newTags });
    };

    const addButton = () => {
        setPostData({ ...postData, buttons: [...postData.buttons, { caption: '', tag: '' }] });
    };

    const removeButton = (index) => {
        const newTags = postData.buttons.filter((_, i) => i !== index);
        setPostData({ ...postData, buttons: newTags });
    };

    const handleTypeChange = (event) => {
        setPostData({ ...postData, viewportType: event.target.value });
    }

    const handleLabelChange = (index, field, event) => {
        const newLabels = [...postData.labels];
        newLabels[index] = { ...newLabels[index], [field]: event.target.value };
        setPostData({ ...postData, labels: newLabels });
    };

    const addLabel = () => {
        setPostData({ ...postData, labels: [...postData.labels, { delimiter: '', index: 0, prefix: '' }] });
    };

    const removeLabel = (index) => {
        const newLabels = postData.labels.filter((_, i) => i !== index);
        setPostData({ ...postData, labels: newLabels });
    };

    const handleCommentCheckboxChange = (event) => {
        setPostData({ ...postData, useComments: event.target.checked });
    }

    useEffect(() => {
        if (post) {
            setPostData(post);
        }
        else {
            clear();
        }
    }, [post]);

    return (
        <>
        <StyledPaper>
            <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Typography variant="h6">Project {currentId ? 'Editor': 'Creator'}</Typography>
                <StyledTagsContainer>
                    <StyledTagField><TextField name="title" variant="outlined" label="Title" fullWidth value={postData.title} onChange={(e) => setPostData({ ...postData, title: e.target.value })} /></StyledTagField>
                    <StyledTagField><TextField name="description" variant="outlined" label="Project description" fullWidth multiline rows={4} value={postData.description} onChange={(e) => setPostData({ ...postData, description: e.target.value })} /></StyledTagField>
                </StyledTagsContainer>
                
                <StyledTagsContainer>
                    <Typography variant="h6">Feedback</Typography>
                    <StyledTagFieldContainer>
                        {postData.buttons.map((tag, index) => (
                            <StyledTagField key={index}>
                                <TextField
                                    variant="outlined"
                                    label={`Option ${index + 1}`}
                                    fullWidth
                                    value={tag.caption}
                                    onChange={(e) => handleButtonChange(index, 'caption', e)}
                                />
                                <StyledRemoveButton
                                    onClick={() => removeButton(index)}
                                ><CloseIcon fontSize="small" /></StyledRemoveButton>
                            </StyledTagField>
                        ))}
                        <StyledAddOptionButton>
                            <Button fullWidth variant="contained" color="primary" onClick={addButton}>Add Option</Button>
                        </StyledAddOptionButton>
                    </StyledTagFieldContainer>
                </StyledTagsContainer>

                <StyledTagsContainer>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={postData.useComments}
                                onChange={handleCommentCheckboxChange}
                                color="primary"
                            />
                        }
                        label="Use optional comment field"
                    />
                </StyledTagsContainer>
                

                <StyledTagsContainer>
                <Typography variant="h6" onClick={toggleExpand} style={{ cursor: 'pointer' }}>
                    Advanced settings
                    {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Typography>
                {isExpanded && (
                    <>
                        <StyledTagsContainer>
                            <Typography variant="h6">Labels</Typography>
                            {postData.labels.map((label, index) => (
                                <StyledTagField key={index}>
                                    <TextField
                                        variant="outlined"
                                        label={`Prefix`}
                                        fullWidth
                                        value={label.prefix}
                                        onChange={(e) => handleLabelChange(index, 'prefix', e)}
                                    />
                                    <TextField
                                        variant="outlined"
                                        label={`Delimiter`}
                                        fullWidth
                                        value={label.delimiter}
                                        onChange={(e) => handleLabelChange(index, 'delimiter', e)}
                                    />
                                    <TextField
                                        variant="outlined"
                                        label={`Index`}
                                        fullWidth
                                        value={label.index}
                                        onChange={(e) => handleLabelChange(index, 'index', e)}
                                    />
                                    <StyledRemoveButton
                                        onClick={() => removeLabel(index)}
                                    ><CloseIcon fontSize="small" /></StyledRemoveButton>
                                </StyledTagField>
                            ))}
                            <StyledTagField>
                            <Button variant="contained" color="primary" onClick={addLabel}>Add Label</Button>
                            </StyledTagField>
                        </StyledTagsContainer>
                        <StyledViewportType>
                            <Typography variant="h6">Viewport type:</Typography>
                            <StyledDropdown onChange={handleTypeChange} value={postData.viewportType}>
                                <option value="simple">Single Viewport</option>
                                <option value="threeway">Three-way viewport</option>
                            </StyledDropdown>
                        </StyledViewportType>
                        <StyledFileInput><Typography variant="h6">Thumbnail</Typography><FileBase label="Thumbnail" type="file" multiple={false} onDone={({ base64 }) => setPostData({ ...postData, thumbnail: base64 })} /></StyledFileInput>
                    </>
                )}
                </StyledTagsContainer>
                
                <StyledSubmitButton>
                <Button variant="contained" color="primary" size="normal" type="submit" fullWidth>{ post ? "Save" : "Publish"}</Button>
                
                </StyledSubmitButton>

                <StyledSubmitButton>
                <Button variant="contained" color="secondary" size="small" onClick={clear} fullWidth>Clear</Button>
                </StyledSubmitButton>
            </form>
        </StyledPaper>
        {(currentId && (user?.result?.sub === post?.creator || user?.result?._id === post?.creator)) && (
            <Manager post={post} postData={postData} setPostData={setPostData} currentId={currentId} setCurrentId={setCurrentId}/>
        )}
        </>
    );
}

export default Form;