import React from 'react';
import { useSelector } from 'react-redux';

import Thumbnail from './Thumbnail/Thumbnail';
import Examples from './Examples/Examples';
import AboutUs from '../AboutUs/AboutUs';
import { StyledContainer, StyledGrid } from './styles';

const Posts = ({ currentId, user, setCurrentId }) => {
  const posts = useSelector(state => state.posts);

  return (
    <>
      <StyledContainer container spacing={2}>
        {posts.map((post) => (
          <StyledGrid key={post._id} item xs={12} sm={12}>
            <Thumbnail post={post} user={user} setCurrentId={setCurrentId} />
          </StyledGrid>
        ))}
      </StyledContainer>
      <AboutUs />
      <Examples />
    </>
);
};

export default Posts;