import { styled } from '@mui/material/styles';
import { AppBar, Toolbar, Button, Paper, IconButton, Typography} from '@mui/material';
import { Link } from "react-router-dom";


// Define styled components
export const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(4, 0, 0, 0 ),
  width: '100%',
}));


export const StyledTagsContainer = styled('div')(({ theme }) => ({
  width: '97%',
  margin: theme.spacing(2, 1, 2, 1),
}));

export const StyledSubmitButton = styled('div')(({ theme }) => ({
  width: '97%',
  display: 'flex',
  margin: theme.spacing(2, 2, 2, 2),
}));

export const StyledTagFieldContainer = styled('div')(({ theme }) => ({
  width: '40%',
  marginBottom: theme.spacing(2),
}));

export const StyledTagField = styled('div')(({ theme }) => ({
  width: "100%",
  display: 'flex',
  alignItems: 'center',
  margin: theme.spacing(1, 0, 2, 0),
  '& > *:not(:last-child)': {
      marginRight: theme.spacing(1),
  },
}));

export const StyledRemoveButton = styled(IconButton)(({ theme }) => ({
  position: 'relative',
  top: '-30px',
  right: '5px',
  backgroundColor: 'red',
  color: 'white',
  minWidth: '24px',
  height: '24px',
  borderRadius: '50%',
  padding: '1px',
  lineHeight: '24px',
  justifyContent: 'flex-center',
}));

export const StyledAddOptionButton = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: "90%",
  // justifyContent: 'flex-start',
  margin: theme.spacing(2, 1, 2, 1)
}));

export const StyledViewportType = styled('div')(({ theme }) => ({
  width: '97%',
  display: 'flex',
  margin: theme.spacing(2, 0, 2, 0),
}));

export const StyledDropdown = styled('select')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
  boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
  backgroundColor: 'white',
  margin: theme.spacing(0, 0, 0, 4),
}));

export const StyledFileInput = styled('div')(({ theme }) => ({
  width: '97%',
  margin: '10px 0',
}));