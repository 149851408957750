import { styled } from '@mui/material/styles';
import { AppBar, Toolbar, Button, Paper, IconButton, Typography} from '@mui/material';
import { Link } from "react-router-dom";


// Define styled components
export const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0, 0, 0),
    width: '100%',
}));

export const StyledForm = styled('div')(({ theme }) => ({
  
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
}));

export const StyledFileInput = styled('div')(({ theme }) => ({
    width: '97%',
    margin: '10px 0',
}));

export const StyledHeading = styled(Typography)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 0'
}));

export const StyledText = styled(Typography)(({ theme }) => ({
    width: '90%',
    padding: "20px 20px 20px 20px",
    margin: '10px 0'
}));

export const StyledButtonSubmit = styled(Button)(({ theme }) => ({
    width: '97%',
    display: 'flex',
    margin: theme.spacing(2, 2, 2, 2),
}));

export const StyledTagsContainer = styled('div')(({ theme }) => ({
    width: '97%',
      marginTop: theme.spacing(2),
}));

export const StyledReviewsContainer = styled('div')(({ theme }) => ({
    width: '80%',
    padding: "20px",
}));

export const StylesReviewsNewContainer = styled('div')(({ theme }) => ({
    width: '97%',
      marginTop: theme.spacing(2),
}));

export const StyledTagField = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    '& > *:not(:last-child)': {
        marginRight: theme.spacing(1),
    },
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
      position: 'relative',
      top: '-30px',
      right: '5px',
      backgroundColor: 'red',
      color: 'white',
      minWidth: '24px',
      height: '24px',
      borderRadius: '50%',
      padding: '1px',
      lineHeight: '24px',
      justifyContent: 'flex-center',
}));