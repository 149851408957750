import React from 'react';
import { Typography, Fade } from '@mui/material';
import { StyledCard } from './styles';

const AboutUs = () => {

  return (
    <>
    <StyledCard>
      <Fade in={true} timeout={1000}>
        <div>
        <Typography variant="h5" gutterBottom>Welcome to naesView!</Typography>
        <Typography variant="h7" gutterBottom>A website for easy medical image evaluations.</Typography>
        </div>
      </Fade>
    </StyledCard>
    <StyledCard>
      <Fade in={true} timeout={2000}>
        <div>
        <Typography variant="h7" gutterBottom>Create your custom page and invite others to start reviewing!</Typography>
        <Typography variant="h7" gutterBottom>A website for easy medical image evaluations.</Typography>
        </div>
      </Fade>
    </StyledCard>
    </>
  );
}

export default AboutUs;