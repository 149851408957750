import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Grow, Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { getPosts } from "./actions/posts";
import Post from "./components/Posts/Post/Post";
import Posts from "./components/Posts/Posts";
import Form from "./components/Form/Form";
import Auth from "./components/Auth/Auth";
import RecoveryPage from "./components/Auth/RecoveryPage/RecoveryPage";
import ResetPasswordPage from "./components/Auth/ResetPasswordPage/ResetPasswordPage";
import PrivacyPolicyPage from "./components/PrivacyPolicyPage/PrivacyPolicyPage";
import TermsAndConditionsPage from "./components/TermsAndConditionsPage/TermsAndConditionsPage";
import { StyledContainer, StyledGrid } from "./styles";
import Navbar from "./components/Navbar/Navbar";

const App = () => {
  const [currentId, setCurrentId] = useState(null);
  const [ user, setUser ] = useState(JSON.parse(localStorage.getItem('profile')));
  const dispatch = useDispatch();
  const NEXT_PUBLIC_GOOGLE_API_TOKEN = '749088938747-heatvg26t1m041tdb61u5fjvqbdj9c16.apps.googleusercontent.com';
  
  useEffect(() => {
    dispatch(getPosts());
  }, [user, currentId, dispatch]);

  return (
    <GoogleOAuthProvider clientId={`${NEXT_PUBLIC_GOOGLE_API_TOKEN}`}>
      <BrowserRouter>
      <Navbar setCurrentId={ setCurrentId } user={user} setUser={setUser}/>
      <StyledContainer>
        <Grow in>
            <StyledGrid container>
                <Routes>
                  <Route path="/" element={ <Posts currentId={currentId} user={user} setCurrentId={setCurrentId}/> } />
                  <Route path="/create-post" element={ <Form currentId={currentId} setCurrentId={setCurrentId}/> } />
                  <Route path="/user-profile" element={ <Grid item xs={12} sm={4}> </Grid> } />
                  <Route path="/auth" element={ <Auth /> } />
                  <Route path="/post/:id" element={ <Post /> } />
                  <Route path="/recovery" element={ <RecoveryPage /> } />
                  <Route path="/reset-password/:token" element={ <ResetPasswordPage /> } />
                  <Route path="/privacy-policy" element={ <PrivacyPolicyPage /> } />
                  <Route path="/terms-and-conditions" element={ <TermsAndConditionsPage /> } />
                </Routes>
            </StyledGrid>
        </Grow>
        </StyledContainer>
      </BrowserRouter>
    </GoogleOAuthProvider>
    
  );
}

export default App;