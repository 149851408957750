import dicomParser from 'dicom-parser';
import * as nifti from 'nifti-reader-js';
import * as cornerstone from '@cornerstonejs/core';
import cornerstoneDICOMImageLoader from '@cornerstonejs/dicom-image-loader';
import { mat3 } from 'gl-matrix';
import vtkImageData from '@kitware/vtk.js/Common/DataModel/ImageData';
import vtkDataArray from '@kitware/vtk.js/Common/Core/DataArray';
import { fetchNIFTI, fetchImageStack } from './utils';

var zipFile = null;
export function setZipFile(file) {
  zipFile = file;
}

export function naesNIFTIVolumeLoader(volumeId) {
  const promise = new Promise((resolve, reject) => {
    try {
      const path = volumeId.substring(volumeId.indexOf(":") + 3)
      fetchNIFTI(zipFile, path).then(arrayBuffer => {
        if (nifti.isCompressed(arrayBuffer)) {
          arrayBuffer = nifti.decompress(arrayBuffer);
        }
        const header = nifti.readHeader(arrayBuffer);
        const imageData = nifti.readImage(header, arrayBuffer);
        var volume = getNIFTIVolume(header, imageData, volumeId)
        resolve(volume);
      })
    } 
    catch (error)
    {
      reject(new Error(error));
    }
  });
  return {
    promise,
  };
}

export const stackImagesInto3DArray = (volumeId, imageDataArray) => {
  if (imageDataArray.length === 0) {
    throw new Error('No image data available to stack.');
  }
  const width = imageDataArray[0].width;
  const height = imageDataArray[0].height;
  const depth = imageDataArray.length;
  const pixelCount = width * height * depth;

  const volume = new Uint8ClampedArray(width * height * depth);
  imageDataArray.forEach((imageData, z) => {
    console.log(imageData)
    if (imageData.width !== width || imageData.height !== height) {
      throw new Error('Image dimensions do not match.');
    }

    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        const pixelIndex = (y * width + x) * 1;
        const volumeIndex = (z * width * height + y * width + x) * 1;

        volume[volumeIndex] = imageData.data[pixelIndex];       // R
        // volume[volumeIndex + 1] = imageData.data[pixelIndex + 1]; // G
        // volume[volumeIndex + 2] = imageData.data[pixelIndex + 2]; // B
        // volume[volumeIndex + 3] = imageData.data[pixelIndex + 3]; // A
      }
    }
  });

  const spacing = [ 1.0, 1.0, 1.0 ];
  const dimensions = [ width, height, depth ];
  const direction = mat3.fromValues(1, 0, 0, 0, 1, 0, 0, 0, 1);
  const origin = [ 0, 0, 0 ];

  const imgData = vtkImageData.newInstance();

  var vtkArray = vtkDataArray.newInstance({
    numberOfComponents: 1,
    values: volume,
  });

  imgData.setDimensions(dimensions);
  imgData.setSpacing(spacing);
  imgData.setDirection(direction);
  imgData.setOrigin(origin);
  imgData.getPointData().setScalars(vtkArray);


  return new cornerstone.ImageVolume({
    imageIds: [],
    scalarData: volume,
    dimensions: dimensions,
    spacing: spacing,
    origin: origin,
    direction: direction,
    volumeId: volumeId,
    referencedVolumeId: null,
    sizeInBytes: pixelCount * 8,
    imageData: imgData,
    metadata: {
      PhotometricInterpretation : 'rgb',
      generalSeriesModule: {
          modality: 'CR',
      },
      imagePlaneModule: {
          imagePositionPatient: 0,
      }
    },
    referencedImageIds: null,
    additionalDetails: null,
  })
};


export function naesImageVolumeLoader(volumeId) {
  const promise = new Promise((resolve, reject) => {
    try {
      const path = volumeId.substring(volumeId.indexOf(":") + 3)
      fetchImageStack(zipFile, path, volumeId).then(volume => {
        resolve(volume)
      })

    } 
    catch (error)
    {
      reject(new Error(error));
    }
  });
  return {
    promise,
  };
}

export function getNIFTIVolume(header, niftiImage, volumeId) {
  var cols = header.dims[1];
  var rows = header.dims[2];

  var typedData;

  if (header.datatypeCode === nifti.NIFTI1.TYPE_UINT8) {
      typedData = new Uint8Array(niftiImage);
  } else if (header.datatypeCode === nifti.NIFTI1.TYPE_INT16) {
      typedData = new Int16Array(niftiImage);
  } else if (header.datatypeCode === nifti.NIFTI1.TYPE_INT32) {
      typedData = new Int32Array(niftiImage);
  } else if (header.datatypeCode === nifti.NIFTI1.TYPE_FLOAT32) {
      typedData = new Float32Array(niftiImage);
  } else if (header.datatypeCode === nifti.NIFTI1.TYPE_FLOAT64) {
      typedData = new Float64Array(niftiImage);
  } else if (header.datatypeCode === nifti.NIFTI1.TYPE_INT8) {
      typedData = new Int8Array(niftiImage);
  } else if (header.datatypeCode === nifti.NIFTI1.TYPE_UINT16) {
      typedData = new Uint16Array(niftiImage);
  } else if (header.datatypeCode === nifti.NIFTI1.TYPE_UINT32) {
      typedData = new Uint32Array(niftiImage);
  } else {
      return;
  }

  var max = 0.0;
  for (let i = 0; i < typedData.length; i++) {
    if (typedData[i] > max) {
      max = typedData[i];
    }
  }

  const spacing = [header.pixDims[1], header.pixDims[2], header.pixDims[3]];
  const numOfVoxels = rows * cols * header.dims[3];
  const dimensions = [ cols, rows, header.dims[3] ];
  const direction = mat3.fromValues(1, 0, 0, 0, 1, 0, 0, 0, 1); // mat3.fromValues( header.quatern_b, header.quatern_c, header.quatern_d , header.quatern_b, header.quatern_c, header.quatern_d, header.quatern_b, header.quatern_c, header.quatern_d)
  const origin = [ header.affine[3][0], header.affine[3][1], header.affine[3][2] ];

  const imgData = vtkImageData.newInstance();
  const scalarArray = vtkDataArray.newInstance({
    name: 'Pixels',
    numberOfComponents: 1,
    values: typedData,
  });
  
  imgData.setDimensions(dimensions);
  imgData.setSpacing(spacing);
  imgData.setDirection(direction);
  imgData.setOrigin(origin);
  imgData.getPointData().setScalars(scalarArray);

  const volume = new cornerstone.ImageVolume({
    imageIds: [], // imageIds,
    scalarData: typedData,
    dimensions: dimensions,
    spacing: spacing,
    origin: origin,
    direction: direction,
    volumeId: volumeId,
    referencedVolumeId: null,
    sizeInBytes: numOfVoxels * header.numBitsPerVoxel,
    imageData: imgData,
    metadata: {
      imagePixelModule: {
          pixelRepresentation: 1,
          bitsAllocated: 8,
          sizeInBytes: 1,
      },
      PhotometricInterpretation : 'MONOCHROME2',
      generalSeriesModule: {
          modality: 'CR',
      },
      imagePlaneModule: {
          imagePositionPatient: 0,
      }
    },
    referencedImageIds: null,
    additionalDetails: null,
  })

  return volume;
}
