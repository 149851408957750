import React, { useState, useEffect } from 'react';
import { TextField, Button  } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { StyledButtonSubmit, StyledPaper, StyledIconButton, StyledHeading, StyledText, StyledTagField, StyledReviewsContainer, StyledFileInput } from './styles';
import { deletePost, createPost, updatePost, clearReviews } from '../../../actions/posts';
import { loadCases } from '../../../cornerstone/utils';
import { downloadReviews } from '../../../cornerstone/utils';

const Manager = ({ post, postData, setPostData, currentId, setCurrentId }) => {
    const [cases, setCases] = useState(null);
    const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const handleClickOpen = () => {
        setOpenRemoveDialog(true);
    };
  
    const handleClose = () => {
        setOpenRemoveDialog(false);
    };


    const user = JSON.parse(localStorage.getItem('profile'));
    
    const handleSubmit = (e) => {
        e.preventDefault();

        if (currentId) {
            dispatch(updatePost(currentId, { ...postData, name: user?.result?.name }));
        } else {
            dispatch(createPost({ ...postData, name: user?.result?.name }));
        }
        clear();
        navigate("/")

    }
    
    const clear = () => {
        setCurrentId(null);
        setPostData({ title: '', description: '', reviews: [], reviewers: [], buttons: [], thumbnail: '' });
    }

    const handleReviewerChange = (index, event) => {
        const newReviewers = [...postData.reviewers];
        newReviewers[index] = event.target.value;
        setPostData({ ...postData, reviewers: newReviewers });
    };

    const addReviewer = () => {
        setPostData({ ...postData, reviewers: [...postData.reviewers, ''] });
    };

    const removeReviewer = (index) => {
        const newReviewers = postData.reviewers.filter((_, i) => i !== index);
        setPostData({ ...postData, reviewers: newReviewers });
    };

    const handleDownload = () => {
        downloadReviews(cases, postData.reviews, postData.title);
    }

    useEffect(() => {
        if (post) {
            setPostData(post);
        }
        else {
            clear();
        }
    }, [post]);

    if (!user?.result?.name) {
        return (
            <StyledPaper>
                <StyledText variant="h6" align="center">
                    Please sign in to create your own memories and like other's memories.
                </StyledText>
            </StyledPaper>
        );
    }
    return (
        <>
      <StyledPaper>
          <StyledHeading variant="h6" centered>Manage Project</StyledHeading>
          <StyledText variant="h8" centered>Press download for anonymous data, or open the reviewed file to connect file indeces to original file names.</StyledText>
          <ToastContainer /><StyledReviewsContainer><StyledFileInput><input type="file" multiple={false} onChange={(event) => { loadCases(event.target.files[0], setCases, toast) }} /></StyledFileInput></StyledReviewsContainer>
          <StyledReviewsContainer>
              <Button variant="contained" onClick={handleDownload}>Download reviews ({postData.reviews.length})</Button>
              <Button size="small" style={{ "color": "red" }} onClick={() => { dispatch(clearReviews(post._id)) }}><DeleteIcon fontSize="small" /> Clear reviews</Button>          
          </StyledReviewsContainer>
          

          <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <StyledReviewsContainer>
              <StyledHeading variant="h6">Reviewers</StyledHeading>
              {postData.reviewers.map((reviewer, index) => (
                  <StyledTagField key={index}>
                      <TextField
                          variant="outlined"
                          label={`Email`}
                          fullWidth
                          value={reviewer}
                          onChange={(e) => handleReviewerChange(index, e)}
                      />
                      <StyledIconButton
                          onClick={() => removeReviewer(index)}
                      ><CloseIcon fontSize="small" /></StyledIconButton>
                  </StyledTagField>
              ))}
              <Button variant="contained" color="primary" onClick={addReviewer}>Add Reviewer</Button>
              
          </StyledReviewsContainer>
          <StyledButtonSubmit variant="contained" color="primary" size="normal" type="submit" fullWidth>Send invitations</StyledButtonSubmit>
          <Button size="small" style={{ "color": "red" }} onClick={handleClickOpen}><DeleteIcon fontSize="small" /> Delete project</Button>
          
          
      </form>
      </StyledPaper>
      <Dialog
        open={openRemoveDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this project and all of its reviews? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Back</Button>
          <Button onClick={() => {dispatch(deletePost(post._id)); navigate("/"); } } autoFocus>
            Remove
          </Button>
        </DialogActions>
      </Dialog>
      </>
    );
}

export default Manager;