import React, { useEffect} from "react";
import { Avatar } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CottageSharpIcon from '@mui/icons-material/CottageSharp';

import { jwtDecode } from "jwt-decode";
import { LOGOUT } from "../../constants/actionTypes";
import { StyledAppBar, StyledToolbar, StyledButton, StyledLink, StyledHome, StyledTypography } from './styles';

const Navbar = ({ setCurrentId, user, setUser }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = user?.token;

    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken.exp * 1000 < new Date().getTime()) logout();
    }

    setUser(JSON.parse(localStorage.getItem("profile")));
  }, [location]);

  const logout = () => {
    dispatch({ type: LOGOUT });
    setUser(null);
    navigate('/')
  }

  return (
    <StyledAppBar color="inherit">
      <StyledToolbar>
        {user ? (
          <>
          <StyledTypography variant="h8">{user.result.name}</StyledTypography>
          <Avatar alt={user.result.name} src={user.result.imageUrl}>{user.result.name.charAt(0)}</Avatar>
          <div><StyledButton variant="contained"><StyledLink to="create-post" onClick={() => setCurrentId(null) }>Create project</StyledLink></StyledButton></div>
          <div><StyledButton variant="contained" onClick={logout}>Logout</StyledButton></div>
          </>
        ) : (
          <div><StyledButton variant="contained"><StyledLink to="/auth" >Sign In</StyledLink></StyledButton></div>
        )}    
        <StyledHome to="/" variant="contained" onClick={() => setCurrentId(null) }>
          <CottageSharpIcon sx={{ fontSize: '60px' }}/>
        </StyledHome>
      </StyledToolbar>
    </StyledAppBar>
  );
}

export default Navbar;