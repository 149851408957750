import React from 'react';
import { StyledCard } from './styles';

const TermsAndConditionsPage = () => {

  return (
    <>
    <StyledCard>
      <div>
        <h1>Terms and Conditions</h1>
        <p>
        These are our terms and conditions...
        </p>
      </div>
    </StyledCard>
    </>
  );
}

export default TermsAndConditionsPage;