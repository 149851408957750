import Papa from 'papaparse';
import { BlobReader, BlobWriter, ZipReader } from '@zip.js/zip.js';
import { stackImagesInto3DArray } from './loader';

export const fetchQueue = (fileList, reviews) => {
  var caseQueue = [ ];
  const reviewedFileList = new Set(reviews.map(review => review.fileID));
  for (let i = 0; i < fileList.length; i++) {
    if ((!reviewedFileList.has(i.toString()))) {
      caseQueue.push(fileList[i]);
    }
  }
  return caseQueue;
}

export const loadCases = async (fileBlob, setFiles, toast) => {
  try
  {
    const blobReader = new BlobReader(fileBlob);
    const zipReader = new ZipReader(blobReader);
    const entries = await zipReader.getEntries();
  
    let caseNames = [];
    for (const entry of entries) {
      if (!entry.directory) {
        if (entry.filename.indexOf('/') == -1)
          throw new Error("Zip file must contain directories for independent cases.")
        const caseName = entry.filename.split('/')[0];
        if (!caseNames.includes(caseName)) {
          caseNames.push(caseName);
        }
      }
    }
  
    caseNames.sort();
    await zipReader.close();
    setFiles(caseNames);
  } catch (error) {
    toast(error.toString());
  }
};

export const fetchVolumeIds = async (zipFile, cases, setVolumeIds) => {
  let ids = {};
  for (const caseName of cases)
    ids[caseName] = [];

  try {
    const blobReader = new BlobReader(zipFile);
    const zipReader = new ZipReader(blobReader);
    const entries = await zipReader.getEntries();

    let totalFiles = 0;

    for (const entry of entries) {
      if (entry.directory) continue;
      totalFiles += 1;
      for (const caseName of cases) {
        if (entry.filename.startsWith(caseName + "/") && !entry.directory) {
          if (/\.(jpg|jpeg|png|gif)$/i.test(entry.filename)) {
            const index = entry.filename.toString().lastIndexOf('/') + 1;
            const volumeName = "naesImage://" + entry.filename.substring(0, index)
            if (!ids[caseName].includes(volumeName))
              ids[caseName].push(volumeName);
          } else if (/\.(nii|gz)$/i.test(entry.filename)) {
            ids[caseName].push("naesNIFTI://" + entry.filename);
          } else if (/\.(dcm)$/i.test(entry.filename)) {
            ids[caseName].push("naesDICOM://" + entry.filename);
          } else {
            throw new Error('File type not yet supported: ' + entry.filename);
          }
        }
      }
    }

    for (const caseName of cases) {
      const rootFolders = new Set();
      ids[caseName].forEach(p => {
        const rootFolder = p.split(":")[0];
        rootFolders.add(rootFolder);
      });

      if (rootFolders.size > 1) {
        throw new Error('Not supported mixing data types for case: ' + caseName);
      } else if (rootFolders.size === 0) {
        throw new Error('No files found for case: ' + caseName);
      }
    }
    await zipReader.close();
    setVolumeIds(ids);

  } catch (error) {
    console.error('Error loading zip:', error);
    alert(error.toString());
  }
};

export function fetchNIFTI(zipFile, filePath) {
  return new Promise((resolve, reject) => {
    const blobReader = new BlobReader(zipFile);
    const zipReader = new ZipReader(blobReader);
    zipReader.getEntries().then(entries => {
      for (const entry of entries) {
        if (entry.filename.startsWith(filePath) && !entry.directory) {
          entry.getData(new BlobWriter()).then(function(blob) {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsArrayBuffer(blob);
          }).catch(reject);
        }
      }
    }
    )
  })
}



function blobToArrayBuffer(imgUrl) {
  return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function() {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        resolve(imageData);
      };
      img.onerror = function() {
        reject(new Error('Failed to load image'));
      };
      img.src = imgUrl;
  });
}
export function fetchImageStack(zipFile, filePath, volumeId) {
  return new Promise((resolve, reject) => {
    const blobReader = new BlobReader(zipFile);
    const zipReader = new ZipReader(blobReader);
    var promises = [];
    zipReader.getEntries().then(entries => {
      for (const entry of entries) {
        if (entry.filename.startsWith(filePath) && !entry.directory) {
          promises.push(new Promise((resolve, reject) => {
            entry.getData(new BlobWriter()).then(function(data) {
            
              const reader = new FileReader();
              reader.onloadend = () => {
                const blob = new Blob([data], { type: 'image/png' });
                const url = URL.createObjectURL(blob);
                resolve(blobToArrayBuffer(url))
              };
              reader.onerror = reject;
              reader.readAsArrayBuffer(data);
            })
          }).catch(reject));
        }
      }
      Promise.all(promises).then(imageDataArray => {
        const volume = stackImagesInto3DArray(volumeId, imageDataArray);
        resolve(volume);
      })
      .catch(error => {
        reject(new Error('Failed to process images: ' + error.message));
      });
    });

  })
}

export const downloadReviews = (imageNames, reviews, title) => {
  const csvData = [];

  const userIdMap = {};
  let userCounter = 0;

  csvData.push(['File name', 'Reviewer', 'Review']);
  reviews.forEach(item => {
    const { fileID, userID, review } = item;

    if (!userIdMap[userID]) {
      userIdMap[userID] = `reviewer#${userCounter}`;
      userCounter += 1;
    }
    const row = [
      imageNames ? imageNames[parseInt(fileID)] : fileID,
      userIdMap[userID],
      review
    ];

    csvData.push(row);
  })
  const csvString = Papa.unparse(csvData, { header: false });

  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = `${title}_${getCurrentDateMMDD()}_reviews.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const getCurrentDateMMDD = () => {
  const now = new Date(Date.now());
  const month = (now.getMonth() + 1).toString().padStart(2, '0');
  const day = now.getDate().toString().padStart(2, '0');
  return month + day;
};

export const getLabelText = (fileName, delimiter, index) => {
  try {
      const parts = fileName.split(delimiter);
      return parts[index];
  } catch (error) {
      return '';
  }
};



export const enterFullScreen = (ref, setIsFullScreen) => {
  return () => {
    if (ref.requestFullscreen) {
        ref.requestFullscreen();
    } else if (ref.mozRequestFullScreen) { // Firefox
        ref.mozRequestFullScreen();
    } else if (ref.webkitRequestFullscreen) { // Chrome, Safari and Opera
        ref.webkitRequestFullscreen();
    } else if (ref.msRequestFullscreen) { // IE/Edge
        ref.msRequestFullscreen();
    }
    setIsFullScreen(true);
  }
};

export const exitFullScreen = (ref, setIsFullScreen) => {
  return () => {
    if (ref.exitFullscreen) {
        ref.exitFullscreen();
    } else if (ref.mozCancelFullScreen) { // Firefox
        ref.mozCancelFullScreen();
    } else if (ref.webkitExitFullscreen) { // Chrome, Safari and Opera
        ref.webkitExitFullscreen();
    } else if (ref.msExitFullscreen) { // IE/Edge
        ref.msExitFullscreen();
    }
    setIsFullScreen(false);
  }
};