import React from 'react';
import { CardContent, Button } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useNavigate } from "react-router-dom";

import { StyledCard, StyledGrid, StyledDescription, StyledCardMedia, StyledEditButton, StyledTitle } from './styles';

const Thumbnail = ({ post, user, setCurrentId }) => {
    const navigate = useNavigate();

    return (
        <StyledCard>
            <StyledGrid>
                <CardContent onClick={() => { setCurrentId(post._id); navigate("/post/" + post._id) } }>
                    <StyledCardMedia image={post.thumbnail || 'https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png' } title={post.title} />
                </CardContent>
                <CardContent>
                    <StyledTitle onClick={() => { setCurrentId(post._id); navigate("/post/" + post._id) } } variant="h4" gutterBottom>{post.title}</StyledTitle>
                    <StyledDescription variant="contained" gutterBottom>{post.description.length > 0 ? post.description.replace(/(.{160})..+/, "$1…") : ""}</StyledDescription>
                </CardContent>
                {(user?.result?.sub === post?.creator || user?.result?._id === post?.creator) && (
                    <StyledEditButton>
                        <Button size="large" onClick={() => { setCurrentId(post._id); navigate("/create-post") } }><MoreHorizIcon fontSize="default" /></Button>
                    </StyledEditButton>
                )}
            </StyledGrid>
        </StyledCard>
        
    )};


export default Thumbnail;