import { styled } from '@mui/material/styles';
import { Card, Button, CardContent } from '@mui/material';

// Define styled components
export const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderRadius: '15px',
  height: '100%',
  width: '100%',
  padding: theme.spacing(2),
  margin: theme.spacing(4, 0, 0, 0 ),
  position: 'relative',
}));

// Define styled components
export const StyledWorkspace = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  padding: theme.spacing(0.5),
}));

export const StyledFullScreen = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end'
}));

export const StyledDetails = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  margin: theme.spacing(1),
}));

export const StyledCanvas = styled('div')(({ theme }) => ({
  width: "100%",
  height: "500px",
  // display: 'flex',
  justifyContent: 'space-evenly',
}));

export const StyledTagsContainer = styled('div')(({ theme }) => ({
  width: '95%',
  display: 'flex',
  justifyContent: 'center',
  margin: theme.spacing(2),
  padding: theme.spacing(2),
}));

export const StyledLabelContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
}));

export const StyledLabelPrefix = styled('span')(({ theme }) => ({
  fontWeight: 'bold',
  marginRight: theme.spacing(1),
}));

export const StyledLabelTextBox = styled('span')(({ theme }) => ({
  padding: theme.spacing(1),
  border: '1px solid #ccc',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#f9f9f9',
}));
  
export const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  margin: theme.spacing(1),
  padding: theme.spacing(1),
}));