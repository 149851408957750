import React from 'react';
import { StyledCard } from './styles';

const PrivacyPolicyPage = () => {
  return (
    <StyledCard>
      <div>
        <h1>Privacy Policy</h1>

        {/* About this policy */}
        <h2>About this policy</h2>
        <p>
          This Policy describes how we process your personal data at naesView AB (“we”, “us”, “our”). It applies to your use of our website and when using our services (collectively, Services).
        </p>

        {/* Personal data that we collect */}
        <h2>Personal data that we collect</h2>
        <p>We collect the following types of information:</p>

        <h3>User data</h3>
        <p>
          Required to create your naesView account and that enables you to use our Services. This depends on if you use third party services to sign in and you will be asked to provide:
        </p>
        <ul>
          <li>First and last name</li>
          <li>Email address</li>
          <li>Work experience, associations, and positions</li>
        </ul>

        <h3>Payment and purchase data</h3>
        <p>
          In order to purchase Subscriptions to certain Services and perform anti-fraud checks, we may ask you to provide the following data:
        </p>
        <ul>
          <li>First and last name</li>
          <li>Billing address</li>
          <li>Credit card information</li>
        </ul>
        <p>
          Details of your purchase and payment history will be collected and processed until you close your account, or as long as needed by relevant laws (see Section xx).
        </p>

        <h3>Usage data</h3>
        <p>When you are accessing our website or using our Services, we collect and process data:</p>
        <ul>
          <li>When you perform actions within our Services, such as:</li>
          <ul>
            <li>Changes to your profile</li>
            <li>Number of created Projects and their unique IDs</li>
            <li>Number of projects you are associated with, and their IDs</li>
            <li>IDs of collaborators associated with you and your projects</li>
          </ul>
          <li>About technical information, such as:</li>
          <ul>
            <li>Device ID</li>
            <li>Operating system</li>
            <li>Browser type</li>
            <li>Cookie data</li>
          </ul>
          <li>About content you provide to the user forum</li>
        </ul>

        {/* How we use your data */}
        <h2>How we use your data</h2>
        <p>Our purpose for processing your personal data is explained in the table below, together with the legal basis that permits the purpose, and the information type used for each purpose (see Section 2).</p>
        
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid black', padding: '8px' }}>Purpose</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>Legal Basis</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>Information Type</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ border: '1px solid black', padding: '8px' }}>To comply with obligations under a contract with you. This includes our obligations under the <a href="https://naesview.com/terms-and-conditions">Terms of Use</a>, to authorize access to our Services by setting up your account, and to process your payment.</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>Consent, Performance of a contract</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>User data, Payment and purchase data</td>
            </tr>
            <tr>
              <td style={{ border: '1px solid black', padding: '8px' }}>To troubleshoot issues with the Service</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>Consent</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>User data, Usage data</td>
            </tr>
            <tr>
              <td style={{ border: '1px solid black', padding: '8px' }}>To comply with legal obligations in the country/region you are in, Swedish law, since our headquarters is in Sweden, EU legislation that we are subject to, and law enforcement requests we are obligated to comply with</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>Compliance with legal obligations</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>User data, Usage data, Payment and purchase data</td>
            </tr>
            <tr>
              <td style={{ border: '1px solid black', padding: '8px' }}>To conduct business planning and forecasting we will look at aggregated user data and analyze traffic patterns. To gain insights into how you interact with our website, we use Statistics cookies</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>Consent</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>User data, Usage data, Payment and purchase data</td>
            </tr>
            <tr>
              <td style={{ border: '1px solid black', padding: '8px' }}>For marketing and advertising purposes</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>Consent</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>User data, Usage data</td>
            </tr>
            <tr>
              <td style={{ border: '1px solid black', padding: '8px' }}>To establish, exercise, or defend against legal claims. For instance, if we need to provide our lawyers with information pertaining to litigation cases we are involved in</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>Legitimate interest, including seeking legal counsel and protecting ourselves and others in legal proceedings</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>User data, Usage data, Payment and purchase data</td>
            </tr>
            <tr>
              <td style={{ border: '1px solid black', padding: '8px' }}>To detect and prevent fraud and malicious behavior. For instance, when trying to identify misuse of our services by analyzing Usage Data</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>Consent</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>User data, Usage data</td>
            </tr>
            <tr>
              <td style={{ border: '1px solid black', padding: '8px' }}>To inform you about new features and invite you to meet us at, for instance, conferences</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>Consent</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>User data</td>
            </tr>
          </tbody>
        </table>

        {/* Data Retention */}
        <h2>Data Retention</h2>
        <p>
          We have implemented suitable technical and organizational measures to protect your personal data. Technical measures include login procedures, access controls, and distributed servers. As organizational measures, we have implemented security measures such as assigned access rights to ensure that only those who need certain information to perform their tasks have access to it. In the event of data breaches, we may contact you in accordance with Article 34 of the General Data Protection Regulation (GDPR).
        </p>
        <p>
          Your personal data will be retained until you close your account, ask that we close your account (see Section 5) or the purpose of collecting, processing, and using your personal data is fulfilled. The exception is if we are legally required to keep some of it, or if there is a legally justifiable reason to keep some of that data. Examples where your personal data will be retained include:
        </p>
        <ul>
          <li>When there are unresolved issues relating to your account, such as outstanding credits</li>
          <li>To comply with legal or accounting obligations, such as the Accounting act which requires some personal data to be stored for seven years</li>
        </ul>
        <p>
          When personal data is marked for deletion, it will be moved to another database. After being stored for as long as needed under relevant laws, it will be deleted in such a way that it cannot be restored.
        </p>

        {/* Transferring personal data */}
        <h2>Transferring personal data</h2>
        <p>
          We store and process personal data on servers located in the EU/EEA. If data is transferred outside the EU/EEA, we ensure that the recipient adheres to safety standards equivalent to those in the EU/EEA through contractual agreements. This may occur if we are legally required to comply with non-EU/EEA authorities or at your request. Personal data is only shared with others to comply with applicable laws and regulations.
        </p>

        {/* Cookies */}
        <h2>Cookies</h2>
        <p>
          This website uses a Borlabs Cookie, which sets a technically necessary cookie (borlabs-cookie) to store your cookie preferences. Borlabs Cookie does not collect any personal data. The borlabs-cookie stores the consent you have given when you entered the website. If you wish to revoke these consents, simply delete the cookie from your browser. If you re-enter/reload the website, you will be asked again for your cookie consent.
        </p>
        <p>
          We use cookies to gather statistics about how users interact with our website, allowing us to optimize performance and accurately plan for service needs and resources.
        </p>

        {/* Your data protection rights */}
        <h2>Your data protection rights</h2>
        <p>Your rights to control our collection and use of your personal data are protected under GDPR. Below we describe your rights, and under which circumstances they apply. You can exercise these rights by contacting us at info@naesView.com. If you make a request, we have one month to respond to you.</p>
        <ul>
          <li><strong>Information:</strong> You have the right to be informed about what data we collect about you and how we process this data. We inform you through this policy, when using our services, and when communicating with naesView through info@naesView.com.</li>
          <li><strong>Access:</strong> You can request access for copies of your personal data. We may charge you a small fee for this service.</li>
          <li><strong>Rectification:</strong> You can request that we correct information you believe to be inaccurate and to complete the information you believe is incomplete.</li>
          <li><strong>Erasure:</strong> You have the right to request that we delete your personal data that we process under the legal basis of consent. There is data that we cannot delete, for instance when it is necessary to process the data for the purpose it was collected, to protect our Services from fraud, for legal claims, and to comply with legal obligations.</li>
          <li><strong>Restriction:</strong> You can request that we stop processing all or some of your personal data if it is inaccurate, our processing is unlawful, we do not need it to fulfil a specific purpose, or pending an objection request.</li>
          <li><strong>Notification obligation:</strong> You have the right to be informed about rectifications or erasure of personal data or restriction of processing.</li>
          <li><strong>Portability:</strong> You have the right to receive personal data concerning you, and request that it be transferred to another data controller. Some data need to be kept, see the point Erasure.</li>
          <li><strong>Objection:</strong> You have the right to object to our processing of your personal data that we process under the legal basis of legitimate interest.</li>
          <li><strong>Withdrawal of consent:</strong> Without charge, you have the right to withdraw your consent to us collecting or using your personal data, if the legal basis that permits our processing of that data is your consent.</li>
        </ul>

        {/* Changes to this policy */}
        <h2>Changes to this policy</h2>
        <p>When significant changes are made to this policy, we will notify you prominently and ask for renewed consent if required.</p>

        {/* Contact information */}
        <h2>Contact information</h2>
        <p>
          Under this policy, naesView AB acts as the data controller for all personal data processed. For any questions about our privacy policy, or if you want to exercise any one of your data protection rights please contact us at info@naesView.com. You can issue a complaint or make a report to the Swedish Authority for Privacy Protection (IMY) at <a href="https://www.imy.se/en/">https://www.imy.se/en/</a>.
        </p>

        <p><em>Last updated: 2024-08-28</em></p>
      </div>
    </StyledCard>
  );
};

export default PrivacyPolicyPage;