import { styled } from '@mui/material/styles';
import { Container, Grid } from "@mui/material";

// Define styled components
export const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledGrid = styled(Grid)(({ theme }) => ({
  borderRadius: 15,
  margin: theme.spacing(8, 0, 0, 0),
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));