import React, { useState } from "react";
import { Button, Grid, Typography, Container } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import InputField from "../../InputField/InputField";
import { resetPassword } from "../../../actions/auth";
import { StyledPaper, StyledAvatar, StyledForm, StyledSubmit } from "./styles";

const initialState = { token: null, password: "", confirmPassword: "" };

const ResetPasswordPage = () => {
  const { token } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [isSignup, setIsSignup] = useState(false);
  const [formData, setFormData] = useState({...initialState, token: token})
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    
    dispatch(resetPassword(formData, navigate));
  }

  const handleChange = (e) => { 
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }
  
  const handleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  }

  return (
    <Container component="main" maxWidth="xs">
      <StyledPaper elevation={3}>
        <StyledAvatar><LockOutlinedIcon /></StyledAvatar>
        <Typography variant="h5">Enter new password</Typography>
        <StyledForm onSubmit={handleSubmit}>
          <div>
            <Grid container spacing={2}>
              <InputField name="password" value={formData.password} label="Password" type={showPassword ? "text" : "password"} handleShowPassword={handleShowPassword} handleChange={handleChange} />
              <InputField name="confirmPassword" label="Repeat Password" type="password" handleChange={handleChange} />
            </Grid>
          </div>
          <StyledSubmit><Button type="submit" fullWidth variant="contained" color="primary">Change password</Button></StyledSubmit>
        </StyledForm>
      </StyledPaper>
    </Container>
  );
}

export default ResetPasswordPage;