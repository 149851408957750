import { styled } from '@mui/material/styles';
import { CardMedia, Typography, Card } from '@mui/material';

// Define styled components
export const StyledCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    // position: 'relative',
    width: '100%',
    height: 160
}));

export const StyledGrid = styled('div')(({ theme }) => ({
    display: 'flex',
    position: 'relative',
    margin: theme.spacing(0, 0, 0, 0),
    width: '100%',
}));

export const StyledDescription = styled(Typography)(({ theme }) => ({
    fontSize: '0.8rem',
    width: "100%",
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
    width: 200,
    padding: '0 0px',
    fontWeight: 'bold',
    fontSize: '1.0rem',
    cursor: 'pointer',
    '&:hover': {
        color: theme.palette.primary.main,
    },
}));

export const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
    height: 128,
    width: 128,
    cursor: 'pointer',
    '&:hover': {
        color: theme.palette.primary.main,
    },
    margin: theme.spacing(0, -3, 0, 0),
}));

export const StyledEditButton = styled('div')(({ theme }) => ({
    display: 'flex',
    position: 'absolute',
    top: '5px',
    right: '-5px',
    color: 'black',
}));