import { AUTH } from '../constants/actionTypes';
import * as api from '../api/index.js';

export const signin = (formData, navigate) => async (dispatch) => {
  try {
    const { data } = await api.signIn(formData);
    dispatch({ type: AUTH, data });
    navigate('/');
  } catch (error) {
    console.log(error);
  }
};

export const signup = (formData, navigate) => async (dispatch) => {
  try {
    await api.signUp(formData);
  } catch (error) {
    console.log(error);
  }
};

export const verifyEmail = (formData, navigate) => async (dispatch) => {
  try {
    const { data } = await api.verifyEmail(formData);
    dispatch({ type: AUTH, data });
    navigate('/');
  } catch (error) {
    console.log(error);
  }
};

export const recovery = (formData, navigate) => async (dispatch) => {
  try {
    await api.recovery(formData);
  } catch (error) {
    console.log(error);
  }
};

export const resetPassword = (formData, navigate) => async (dispatch) => {
  try {
    await api.resetPassword(formData);
    navigate('/');
  } catch (error) {
    console.log(error);
  }
};