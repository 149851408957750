import { styled } from '@mui/material/styles';
import { Card, Box } from '@mui/material';

// Define styled components
export const StyledCard = styled(Card)(({ theme }) => ({
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3, 0, 0, 0),
    height: '100%',
}));

export const StyledBox = styled(Box)(({ theme }) => ({
    width: '100%', 
    borderBottom: 1, 
    borderColor: 'divider'
}));